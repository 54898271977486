




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DigitalContents',
  setup() {
    return {
      pageTitle: 'デジタルフォト・ムービー',
    };
  },
});
